import { message } from 'antd';
// console.log(process.env)
const baseUrl = process.env.REACT_APP_BASE_URL;

interface Option {
  method: MethodType;
  headers?: {
    [propName: string]: string;
  },
  dataType: 'json' | 'formData';
  data: { [propName: string]: string } | FormData
}

type MethodType = 'POST' | 'GET';

const request = async (url: string, option?: Option) => {
  const response = await fetch(baseUrl + url, {
    method: option?.method,
    headers: option?.headers,
    body: option?.dataType === 'json' ? JSON.stringify(option?.data) : (option?.data as FormData)
  })
  

  if (response.ok) {
    let res = await response.json();
    if (res.code !== 200) {
      message.error(res.message);
    }
    return res;
  } else {
    throw new Error(response.statusText);
  }
}



export default request;