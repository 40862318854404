import React, { useState, useEffect } from 'react'
import './index.less';
import MyUpload from './MyUpload/MyUpload';
import Footer from '../../components/Footer';
import { Button, Input, message, Modal } from 'antd';
import request from '../../utils/request';
import logo from '../../accets/images/logo2.png'
const { TextArea } = Input;


export default function Home() {
  const [hashValue, setHashValue] = useState('');
  const [currentHash, setCurrentHash] = useState('');
  const [parentHash, setParentHash] = useState('');
  const [nodeSize, setNodeSize] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blockInfo, setBlockInfo] = useState({
    fileName: '',
    uploadTime: '',
    fileSize: 0
  })
  const [loading, setLoading] = useState(false);


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const keepTwoDecimal = (num: number) => {
    return Math.round(num * 100) / 100;
  };

  useEffect(() => {
    (async function () {
      try {
        const res = await request('/document/queryBlockInfo');
        console.log(res)
        if (res.code === 200) {
          setCurrentHash(res.data.currentHash)
          setParentHash(res.data.parentHash)
          setNodeSize(res.data.nodeSize + 15606)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  // 查询区块
  const queryBlock = (hash: string) => {
    setLoading(true)
    let formData = new FormData();
    formData.append('hashCode', hash);
    request('/document/verify', {
      method: 'POST',
      dataType: 'formData',
      data: formData
    }).then(res => {
      if (res.data.code === 200) {
        setBlockInfo({
          fileName: res.data.fileName,
          uploadTime: res.data.uploadTime,
          fileSize: res.data.fileSize
        })
        setIsModalVisible(true);
      } else {
        Modal.error({
          content: '未查询到数据，此哈希不存在于链上',
        });
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className="home">
      <Modal title="区块信息"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
          </Button>
        ]}
      >
        <p>文件名: {blockInfo.fileName}</p>
        <p>上传时间: {blockInfo.uploadTime}</p>
        <p>文件大小: {keepTwoDecimal(blockInfo.fileSize/1024/1024)}MB</p>
      </Modal>
      <div className="banner">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="number">
          <div className="label">当前区块链高度</div>
          <div className="value" style={{ fontSize: '30px' }}>{nodeSize}</div>
          <div className="label hash-label">当前块哈希</div>
          <div className="value hash-value">{currentHash}</div>
          <div className="label hash-label">上一个块哈希</div>
          <div className="value hash-value">{parentHash}</div>
        </div>
      </div>
      <div className="introduced">
        <div className="title">E诺签</div>
        <div className="content">《E诺链》是盈诺签开发的会员联盟机构组成的区块链，用于保证电子证据的公平公正性，其中包括公证处、互联网法院、线上仲裁等机构，每个成员均代表本链上的一个数据存储节点，签诺签产生的各种电子证据通过加密后均在公平链上存储，进行证据固化，各种电子证据做到可追溯，其过程和产生都有据可查，可验真伪，还原事实真像。</div>
        <div className="characteristic">
          <div className="c-item">
            <div className="icon">
              <i className="iconfont icon-baohusan" style={{ fontSize: '45px' }}></i>
            </div>
            <div className="c-item-title">防篡改</div>
            <div className="c-i-c">后期无法对往期数据进行修改伪造；</div>
          </div>
          <div className="c-item">
            <div className="icon">
              <i className="iconfont icon-qukuailian" style={{ fontSize: '40px' }}></i>
            </div>
            <div className="c-item-title">区块链</div>
            <div className="c-i-c">多点存储，节点之间互为信用背书；</div>
          </div>
          <div className="c-item">
            <div className="icon">
              <i className="iconfont icon-a-anquan1" style={{ fontSize: '40px' }}></i>
            </div>
            <div className="c-item-title">高安全</div>
            <div className="c-i-c">数据经过哈希值上链存储，安全保密；</div>
          </div>
          <div className="c-item">
            <div className="icon">
              <i className="iconfont icon-chaxun" style={{ fontSize: '35px' }}></i>
            </div>
            <div className="c-item-title">可查验</div>
            <div className="c-i-c">方便管理、查询、验证等操作；</div>
          </div>
          <div className="c-item">
            <div className="icon">
              <i className="iconfont icon-sifa" style={{ fontSize: '35px' }}></i>
            </div>
            <div className="c-item-title">司法公信</div>
            <div className="c-i-c">公信力高，司法证据效力强；</div>
          </div>
        </div>
      </div>
      <div className="search">
        <div className="title">查询区块</div>
        <div className="form">
          <TextArea placeholder="请输入哈希值" value={hashValue} onChange={(e) => { setHashValue(e.target.value) }} />
          {/* <Input style={{marginTop: '20px'}} placeholder="私钥" value=""/> */}
          <div className="btns">
            <Button style={{ marginRight: '50px' }} onClick={() => {
              setHashValue('');
            }}>清空</Button>
            <Button type="primary" loading={loading} onClick={() => {
              if (!hashValue) {
                message.error('请输入哈希值');
              } else {
                queryBlock(hashValue)
              }
            }}>查询</Button>
          </div>
        </div>
      </div>
      <div className="verification">
        <div className="title">文件验证</div>
        <MyUpload />
      </div>
      <Footer />
    </div>
  )
}
