import React, {useState} from 'react'
import { Upload, Button, message, Modal, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import './MyUpload.less'
import request from '../../../utils/request';

const { Dragger } = Upload;



export default function MyUpload() {
  const [fileData, setFileData] = useState();
  const [loading, setLoading] = useState(false);

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    customRequest: () => {
    },
    beforeUpload: () => {
      return false;
    },
    onChange(info: any) {
      // console.log(info)
      setFileData(info.file);
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  // 文件验证
  const fileverify = () => {
    if (fileData === undefined) {
      message.error('请先选择文件');
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append('file', (fileData as unknown as Blob));
    request('/document/verify', {
      method: 'POST',
      dataType: 'formData',
      data: formData
    } ).then(res => {
      // console.log(res);
      if (res.data.code === 200) {
        Modal.success({
          content: res.data.message,
        });
      } else {
        Modal.error({
          content: res.data.message,
        });
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <div className="my-upload">
      <Spin tip="Loading..." spinning={loading}>
        <Dragger {...props} className="my-dragger">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text" style={{color: 'rgba(0,0,0,0.5)'}}>点击选择，或者拖拽文件到此处，进行上传</p>
        </Dragger>
      </Spin>
      <Button type="primary" style={{width: '100px', margin: '20px auto'}} loading={loading} onClick={() => {
        fileverify()
      }}>验证</Button>
    </div>
  )
}
