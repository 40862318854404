import React from 'react'
import beian from '../../accets/images/beian.png';
import './index.less'

export default function Footer() {
  return (
    <div className="footer">
      <div className="copyright">
        <p style={{margin: '0'}}>© 2020-2022 湖北盈诺网络科技有限公司版权所有</p>
        <a target="_blank" href="https://beian.miit.gov.cn" rel="noreferrer" className="beian">鄂ICP备20007476号</a>
        <img className="beian-icon" src={beian} alt="" />
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42050202000528"
          className="beian" rel="noreferrer">鄂公网安备 42050202000528号</a>
      </div>
    </div>
  )
}
